// THIS FILE IS BEING UPDATED USING GENERATORS. DO NOT EDIT MANUALLY.
//
// Find out more about the usage of i18n generators here libs/cosmos/util-translations/README.md

/** Language scopes that are being used within the application */
export enum LanguageScope {
  EspCommon = 'espCommon',
  EspProducts = 'espProducts',
  EspPresentations = 'espPresentations',
  EspProjects = 'espProjects',
  EspSettings = 'espSettings',
  EspWebsites = 'espWebsites',
  EspMisc = 'espMisc',
  EspProofs = 'espProofs',
  Cosmos = 'cosmos',
  CustomerPortalPresentations = 'customerPortalPresentations',
  CustomerPortalCommon = 'customerPortalCommon',
  CustomerPortalProofs = 'customerPortalProofs',
  EspDecorations = 'espDecorations',
  CustomerPortalQuotes = 'customerPortalQuotes',
  CustomerPortalOrders = 'customerPortalOrders',
  CustomerPortalInvoices = 'customerPortalInvoices',
  EspOrders = 'espOrders',
  Storefront = 'storefront',
  EspSuppliers = 'espSuppliers',
  EspCollections = 'espCollections',
  EspDecorators = 'espDecorators',
  EspCollaborators = 'espCollaborators',
  EspCompanies = 'espCompanies',
  EspLookup = 'espLookup',
  EspActivities = 'espActivities',
  EspCrm = 'espCrm',
  EspSearch = 'espSearch',
  EspProductOptionsAssistant = 'espProductOptionsAssistant',
  EspContacts = 'espContacts',
  EspTasks = 'espTasks',
  EspNotes = 'espNotes',
  CustomerPortalAuth = 'customerPortalAuth',
  EspAuth = 'espAuth',
  EspPreferredSuppliers = 'espPreferredSuppliers',
  Smartlink = 'smartlink',
  WebsiteModules = 'websiteModules',
  EspHome = 'espHome',
  EspCustomProducts = 'espCustomProducts',
  EspMobileAuth = 'espMobileAuth',
  Pim = 'pim',
  EspEmails = 'espEmails',
  EspMobileProducts = 'espMobileProducts',
  EspMobileSuppliers = 'espMobileSuppliers',
  EspNotifications = 'espNotifications',
  EspMobileCollections = 'espMobileCollections',
  EspMobileHome = 'espMobileHome',
  EspDesignStudio = 'espDesignStudio',
  EspMobileMisc = 'espMobileMisc',
  SupplierHome = 'supplierHome',
  SupplierNavigation = 'supplierNavigation',
  SupplierSearch = 'supplierSearch',
  SupplierCreditReports = 'supplierCreditReports',
  EspMobileNotifications = 'espMobileNotifications',
  CheckOut = 'checkout',
  EspStores = 'espStores',
  SupplierProducts = 'supplierProducts',
  EspMobileDecorators = 'espMobileDecorators',
  SupplierDistributors = 'supplierDistributors',
  SupplierShared = 'supplierShared',
  EspMobileSettings = 'espMobileSettings',
  EspPayments = 'espPayments',
  SupplierSettings = 'supplierSettings',
  EspMobileCrm = 'espMobileCrm',
  EspMobileCompanies = 'espMobileCompanies',
  EspMobileCollaborators = 'espMobileCollaborators',
  EspMobileContacts = 'espMobileContacts',
  SupplierAnalytics = 'supplierAnalytics',
  StorefrontMobileProducts = 'storefrontMobileProducts',
  StorefrontMobileStores = 'storefrontMobileStores',
  StorefrontMobileHome = 'storefrontMobileHome',
  StorefrontMobileCollections = 'storefrontMobileCollections',
  EspMobileSearch = 'espMobileSearch',
  StorefrontMobileMisc = 'storefrontMobileMisc',
  StorefrontMobileCart = 'storefrontMobileCart',
  StorefrontMobileCatalog = 'storefrontMobileCatalog',
  EspIntegrations = 'espIntegrations',
  Ppm = 'ppm',
  EspRouting = 'espRouting',
  EspMySearches = 'espMySearches',
}
