// THIS FILE IS BEING UPDATED USING GENERATORS. DO NOT EDIT MANUALLY.
//
// Find out more about the usage of i18n generators here libs/cosmos/util-translations/README.md
import { LanguageScope } from './language-scope.enum';

/**
 * A Map with Key/Value pairs of absolute paths for all translation files according to LanguageScope enum.
 * Is needed in order to load them synchronously in spec files.
 */
export const languageScopeAbsolutePathMap: ReadonlyMap<LanguageScope, string> =
  new Map([
    [LanguageScope.EspCommon, 'libs/esp/common/util-i18n/src/lib/assets'],
    [LanguageScope.EspProducts, 'libs/esp/products/util-i18n/src/lib/assets'],
    [
      LanguageScope.EspPresentations,
      'libs/esp/presentations/util-i18n/src/lib/assets',
    ],
    [LanguageScope.EspProjects, 'libs/esp/projects/util-i18n/src/lib/assets'],
    [LanguageScope.EspSettings, 'libs/esp/settings/util-i18n/src/lib/assets'],
    [LanguageScope.EspMisc, 'libs/esp/misc/util-i18n/src/lib/assets'],
    [LanguageScope.EspWebsites, 'libs/esp/websites/util-i18n/src/lib/assets'],
    [LanguageScope.EspProofs, 'libs/esp/proofs/util-i18n/src/lib/assets'],
    [LanguageScope.Cosmos, 'libs/cosmos/util-i18n/src/lib/assets'],
    [
      LanguageScope.CustomerPortalPresentations,
      'libs/customer-portal/presentations/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.CustomerPortalCommon,
      'libs/customer-portal/common/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.CustomerPortalProofs,
      'libs/customer-portal/proofs/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.EspDecorations,
      'libs/esp/decorations/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.CustomerPortalQuotes,
      'libs/customer-portal/quotes/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.CustomerPortalOrders,
      'libs/customer-portal/orders/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.CustomerPortalInvoices,
      'libs/customer-portal/invoices/util-i18n/src/lib/assets',
    ],
    [LanguageScope.EspOrders, 'libs/esp/orders/util-i18n/src/lib/assets'],
    [LanguageScope.Storefront, 'libs/storefront/util-i18n/src/lib/assets'],
    [LanguageScope.EspSuppliers, 'libs/esp/suppliers/util-i18n/src/lib/assets'],
    [
      LanguageScope.EspDecorators,
      'libs/esp/decorators/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.EspCollections,
      'libs/esp/collections/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.EspCollaborators,
      'libs/esp/collaborators/util-i18n/src/lib/assets',
    ],
    [LanguageScope.EspCompanies, 'libs/esp/companies/util-i18n/src/lib/assets'],
    [LanguageScope.EspLookup, 'libs/esp/lookup/util-i18n/src/lib/assets'],
    [
      LanguageScope.EspActivities,
      'libs/esp/activities/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.EspProductOptionsAssistant,
      'libs/esp/product-options-assistant/util-i18n/src/lib/assets',
    ],
    [LanguageScope.EspTasks, 'libs/esp/tasks/util-i18n/src/lib/assets'],
    [LanguageScope.EspCrm, 'libs/esp/crm/util-i18n/src/lib/assets'],
    [LanguageScope.EspNotes, 'libs/esp/notes/util-i18n/src/lib/assets'],
    [LanguageScope.EspSearch, 'libs/esp/search/util-i18n/src/lib/assets'],
    [LanguageScope.EspContacts, 'libs/esp/contacts/util-i18n/src/lib/assets'],
    [
      LanguageScope.CustomerPortalAuth,
      'libs/customer-portal/auth/util-i18n/src/lib/assets',
    ],
    [LanguageScope.EspAuth, 'libs/esp/auth/util-i18n/src/lib/assets'],
    [
      LanguageScope.EspPreferredSuppliers,
      'libs/esp/preferred-suppliers/util-i18n/src/lib/assets',
    ],
    [LanguageScope.Smartlink, 'libs/smartlink/util-i18n/src/lib/assets'],
    [
      LanguageScope.WebsiteModules,
      'libs/website-modules/util-i18n/src/lib/assets',
    ],
    [LanguageScope.EspHome, '/libs/esp/home/util-i18n/src/lib/assets'],
    [
      LanguageScope.EspCustomProducts,
      'libs/esp/custom-products/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.EspMobileAuth,
      'libs/esp-mobile/auth/util-i18n/src/lib/assets',
    ],
    [LanguageScope.Pim, 'libs/pim/util-i18n/src/lib/assets'],
    [LanguageScope.EspEmails, 'libs/esp/emails/util-i18n/src/lib/assets'],
    [
      LanguageScope.EspMobileProducts,
      'libs/esp-mobile/products/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.EspMobileSuppliers,
      'libs/esp-mobile/suppliers/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.EspNotifications,
      'libs/esp/notifications/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.EspMobileCollections,
      'libs/esp-mobile/collections/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.EspMobileHome,
      'libs/esp-mobile/home/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.EspDesignStudio,
      'libs/esp/design-studio/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.EspMobileMisc,
      'libs/esp-mobile/misc/util-i18n/src/lib/assets',
    ],
    [LanguageScope.SupplierHome, 'libs/supplier/home/util-i18n/src/lib/assets'],
    [
      LanguageScope.SupplierNavigation,
      'libs/supplier/navigation/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.SupplierSearch,
      'libs/supplier/search/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.SupplierCreditReports,
      'libs/supplier/credit-reports/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.EspMobileNotifications,
      'libs/esp-mobile/notifications/util-i18n/src/lib/assets',
    ],
    [LanguageScope.CheckOut, 'libs/checkout/util-i18n/src/lib/assets'],
    [LanguageScope.EspStores, 'libs/esp/stores/util-i18n/src/lib/assets'],
    [
      LanguageScope.SupplierProducts,
      'libs/supplier/products/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.EspMobileDecorators,
      'libs/esp-mobile/decorators/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.SupplierDistributors,
      'libs/supplier/distributors/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.SupplierShared,
      'libs/supplier/shared/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.EspMobileSettings,
      'libs/esp-mobile/settings/util-i18n/src/lib/assets',
    ],
    [LanguageScope.EspPayments, 'libs/esp/payments/util-i18n/src/lib/assets'],
    [
      LanguageScope.SupplierSettings,
      'libs/supplier/settings/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.EspMobileCrm,
      'libs/esp-mobile/crm/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.EspMobileCompanies,
      'libs/esp-mobile/companies/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.EspMobileCollaborators,
      'libs/esp-mobile/collaborators/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.EspMobileContacts,
      'libs/esp-mobile/contacts/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.SupplierAnalytics,
      'libs/supplier/analytics/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.StorefrontMobileProducts,
      'libs/storefront-mobile/products/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.StorefrontMobileStores,
      'libs/storefront-mobile/stores/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.StorefrontMobileHome,
      'libs/storefront-mobile/home/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.StorefrontMobileCollections,
      'libs/storefront-mobile/collections/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.EspMobileSearch,
      'libs/esp-mobile/search/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.StorefrontMobileMisc,
      'libs/storefront-mobile/misc/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.StorefrontMobileCart,
      'libs/storefront-mobile/cart/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.StorefrontMobileCatalog,
      'libs/storefront-mobile/catalog/util-i18n/src/lib/assets',
    ],
    [
      LanguageScope.EspIntegrations,
      'libs/esp/integrations/util-i18n/src/lib/assets',
    ],
    [LanguageScope.Ppm, 'libs/ppm/util-i18n/src/lib/assets'],
    [LanguageScope.EspRouting, 'libs/esp/routing/util-i18n/src/lib/assets'],
    [
      LanguageScope.EspMySearches,
      'libs/esp/my-searches/util-i18n/src/lib/assets',
    ],
  ]);

export const higherOrderLanguageScopes = new Set(
  [...languageScopeAbsolutePathMap.values()].map(
    (v) => v.split('/').filter(Boolean)[1]
  )
);
