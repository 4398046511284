// eslint-disable-next-line no-restricted-syntax
import dayjs, { type ConfigType, type Dayjs } from 'dayjs/esm';
import advancedFormat from 'dayjs/esm/plugin/advancedFormat';
import customParseFormat from 'dayjs/esm/plugin/customParseFormat';
import duration, { type Duration } from 'dayjs/esm/plugin/duration';
import localeData from 'dayjs/esm/plugin/localeData';
import localizedFormat from 'dayjs/esm/plugin/localizedFormat';
import objectSupport from 'dayjs/esm/plugin/objectSupport';
import relativeTime from 'dayjs/esm/plugin/relativeTime';
import timezone from 'dayjs/esm/plugin/timezone';
import utc from 'dayjs/esm/plugin/utc';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const exported: typeof dayjs = (dayjs as any)
  .extend(relativeTime)
  .extend(localeData)
  .extend(localizedFormat)
  .extend(utc)
  .extend(timezone)
  .extend(advancedFormat)
  .extend(duration)
  .extend(customParseFormat)
  .extend(objectSupport);

export { exported as dayjs, type Dayjs, type ConfigType, type Duration };
